import React from 'react';
import './Links.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const Links = () => {
    return (
        <div className="links-container">
            <FontAwesomeIcon icon={faInstagram} className="social-icon" onClick={() => window.open("https://www.instagram.com/technosnobs/")}/>
            <FontAwesomeIcon icon={faSpotify} className="social-icon" onClick={() => window.open("http://open.spotify.com/playlist/6By4gF40K2LUEWE4tdNVFC?si=pl6ZqpQqTiSy3x-Rr4Yg-w")}/>
            <FontAwesomeIcon icon={faSoundcloud} className="social-icon" onClick={() => window.open("https://soundcloud.com/techno-snobs")}/>
            <FontAwesomeIcon icon={faDiscord} className="social-icon" onClick={() => window.open("https://discord.gg/wBaMkrFBBj")}/>
        </div>
    )
}

export default Links;