import React, { useState } from 'react';
import validator from 'validator';
import Lottie from "react-lottie";
import animationData from "./success-icon.json"
import './Contact.scss';
import './Button.scss';

const Contact = (props) => {
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        message: ""
    });

    const [errors, setErrors] = useState({
        email: false,
        name: false,
        message: false
    });

    const [showSuccess, setShowSuccess] = useState(false);
    
    const handleUpdate = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({...prevState, [name]: value}));
    }

    const validateFormData = () => {
        let hasErrors = false;

        if (!validator.isEmail(formData.email)) {
            setErrors(prevState => ( {...prevState, email: true}));
            hasErrors = true;
        }
        else {
            setErrors(prevState => ( {...prevState, email: false}));
        }

        if (!validator.blacklist(formData.name, '/[.*+?^${}()|[\]\\]/g')) {
            setErrors(prevState => ( {...prevState, name: true}));
            hasErrors = true;
        }
        else {
            setErrors(prevState => ( {...prevState, name: false}));
        }

        if (!validator.blacklist(formData.message, '/[.*+?^${}()|[\]\\]/g')) {
            setErrors(prevState => ( {...prevState, message: true}));
            hasErrors = true;
        }
        else {
            setErrors(prevState => ( {...prevState, message: false}));
        }

        return hasErrors;
    }

    async function handleSubmit() {
        if (validateFormData())
            return;
        try {
            await fetch("https://us-central1-technosnobs-io.cloudfunctions.net/api/sendEmail", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            setShowSuccess(true);
            props.setContactSuccess(true);
        }
        catch (exception) {
            console.log("An error has occurred. Try again later.");
        }
    }

    const options = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const element = showSuccess
        ?   <div className="contact-success">
                <Lottie 
                    options={options}
                    height={75}
                    width={75}
                />
            </div>
        :   <form className="contact-form">
                <div className="name-email-container top-spacer">
                    <input
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={(e) => handleUpdate(e)}
                        className={errors.name ? "input standard-text-input input-error" : "input standard-text-input"}
                        id="left-input"
                    />
                    <input
                        name="email"
                        type="text"
                        placeholder="Email"
                        autoComplete="email"
                        onChange={(e) => handleUpdate(e)}
                        className={errors.email ? "input standard-text-input input-error" : "input standard-text-input"}
                        id="right-input"
                    />
                </div>
                <textarea
                    name="message"
                    placeholder="Message"
                    onChange={(e) => handleUpdate(e)}
                    className={errors.message ? "input body-text-input input-error" : "input body-text-input"}
                />
                <div className="button button-primary submit-button" onClick={() => handleSubmit()}>
                    Submit
                </div>
            </form>

    return (
        <div className="contact-container">
                {element}
        </div>
    )
}

export default Contact;