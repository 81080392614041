import React, { useRef } from 'react'
import backgroundVideo from './assets/background.mp4'
import './BackgroundVideo.css'

const BackgroundVideo = () => {
  const videoRef = useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.7;
  };

  return (
    <video autoPlay muted loop playsInline ref={videoRef} onCanPlay={() => setPlayBack()} poster="./assets/placeholder.jpg" id="background-video">
      <source src={backgroundVideo} type="video/mp4" />
    </video>
  )
}

export default BackgroundVideo;