import './App.css';
import Home from "./Components/Home/Home.js"

const App = () => {
  return (
    <>
      <Home />
    </>
  );
}

export default App;