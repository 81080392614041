import React, { useEffect, useState } from 'react';
import Links from './Links/Links.js';
import Contact from './Contact/Contact.js';
import './BottomContainer.scss';

const BottomContainer = (props) => {
    const [contactSuccess, setContactSuccess] = useState(false);

    useEffect(() => {
        if (props.active != 2)
            setContactSuccess(false);
    }, [props.active]);

    const spotifyElement = <div className="spotify-embed-container">
        <iframe className="spotify-embed" src="https://open.spotify.com/embed/track/716MlK4nIWkrlhYPjmgl8B?utm_source=generator" width="390" height="80" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    </div>;


    return (
        <div className={props.active == 0 ? "bottom-wrapper slide-listen" : (props.active == 2 ? (contactSuccess ? "bottom-wrapper slide-contact-success" : "bottom-wrapper slide-contact-form") : "bottom-wrapper")}>
            <Links />
            {(props.active == 0 && spotifyElement) || (props.active == 2 && <Contact setContactSuccess={setContactSuccess}/>)}
        </div>
    );
}
  
export default BottomContainer;