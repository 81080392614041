import React, {useState} from 'react';
import './Main.scss';
import './Animation.scss';
import BottomContainer from './BottomContainer/BottomContainer.js';

const Title = () => {
    const [activeButton, setActiveButton] = useState(-1);
    const inactiveButtonClasses = "subtitle animated-border";
    const activeButtonClasses = "subtitle active-border";

    const handleClick = (event, num) => {
        setActiveButton(activeButton != num ? num : -1);
        if (num == 1) {
            event.preventDefault();
            window.open("https://linktr.ee/technosnobs1", "_blank");
            window.focus();
        }
        else if (num == 2) {
            // window.open('mailto:akshay@technosnobs.io?subject=Contact');
        }
    }

    return (
        <div className="main-container">
            <div className="top-row">
                <div className="title-container">
                    <div className="glitch title-text" data-text="TECHNO SNOBS">TECHNO SNOBS</div>
                </div>
                <div className="button-bar">
                    <div className={activeButton == 0 ? activeButtonClasses : inactiveButtonClasses} onClick={(event) => handleClick(event, 0)}>LISTEN</div>
                    <div className={activeButton == 1 ? activeButtonClasses : inactiveButtonClasses} id="links-button" onClick={(event) => handleClick(event, 1)}>LINKS</div>
                    <div className={activeButton == 2 ? activeButtonClasses : inactiveButtonClasses} onClick={(event) => handleClick(event, 2)}>CONTACT</div>
                </div>
            </div>
            <div className="bottom-row">
                <BottomContainer active={activeButton}/>
            </div>
            <div className="scanlines"></div>
        </div>
    )
}

export default Title;